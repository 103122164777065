interface ClaimsFlagProps {
  claimsDynamicFilters?: boolean;
  claimsDetails?: boolean;
  claimsDownload?: boolean;
  claimsFilterChips?: boolean;
}

const defaultValues = {
  enableDynamicFilters: false,
  featureFlags: {
    shouldShowClaimDetails: false,
    shouldShowDownloadClaims: false,
    shouldShowFilterChips: false,
  },
};

export const getClaimsFirebaseConfig = ({
  claimsDynamicFilters,
  claimsDetails,
  claimsDownload,
  claimsFilterChips,
}: ClaimsFlagProps) => ({
  ...defaultValues,
  enableDynamicFilters: claimsDynamicFilters,
  featureFlags: {
    ...defaultValues.featureFlags,
    shouldShowClaimDetails: claimsDetails,
    shouldShowDownloadClaims: claimsDownload,
    shouldShowFilterChips: claimsFilterChips,
  },
});
