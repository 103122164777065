import { getBoolean } from 'firebase/remote-config';
import { FEATURE_FLAGS } from '@highmark-web/highmark-common';
import { firebaseRemoteConfig } from '../common/firebase/firebase';

export const useFirebaseLeagueConfig = () => {
  // Claims Feature Flags
  const claimsDynamicFilters = getBoolean(
    firebaseRemoteConfig,
    FEATURE_FLAGS.CLAIMS_ENABLE_DYNAMIC_FILTERS,
  );

  const claimsDetails = getBoolean(
    firebaseRemoteConfig,
    FEATURE_FLAGS.CLAIMS_SHOULD_SHOW_CLAIM_DETAILS,
  );

  const claimsDownload = getBoolean(
    firebaseRemoteConfig,
    FEATURE_FLAGS.CLAIMS_SHOULD_SHOW_DOWNLOAD_CLAIMS,
  );

  const claimsFilterChips = getBoolean(
    firebaseRemoteConfig,
    FEATURE_FLAGS.CLAIMS_SHOULD_SHOW_FILTER_CHIPS,
  );

  // Live Chat Feature Flags
  const liveChatNavigateAway = getBoolean(
    firebaseRemoteConfig,
    FEATURE_FLAGS.LIVE_CHAT_NAVIGATE_AWAY,
  );

  // Wallet Feature Flags
  const walletLiveBalances = getBoolean(
    firebaseRemoteConfig,
    FEATURE_FLAGS.WALLET_ENABLE_NO_NETWORK_LIVE_BALANCES,
  );

  return {
    claimsDynamicFilters,
    claimsDetails,
    claimsDownload,
    claimsFilterChips,
    liveChatNavigateAway,
    walletLiveBalances,
  };
};
