import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import {
  Box,
  HeadingText,
  StackItem,
  StackLayout,
  queryHelpers,
  useMediaQuery,
} from '@leagueplatform/genesis-core';
import { PageHeaderBackground } from '@leagueplatform/web-common-components';
import { useIntl } from '@leagueplatform/locales';
import { BackButtonArrow } from 'components/back-button-arrow/back-button-arrow.component';

/**
 *
 * @param headingId - Pass in a locale key to use locale SDK to formatMessage
 * @param headingText - Pass in string value of text to display as is.
 * @param showBackButton - boolean to determine whether to show back arrow button
 * @returns PageHeaderMobile React component
 */
export const PageHeaderMobile = ({
  headingId,
  headingText,
  showBackButton = true,
}: {
  headingId?: string;
  headingText?: string;
  showBackButton?: boolean;
}) => {
  const { formatMessage } = useIntl();

  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState(100);
  const isLaptopDown = useMediaQuery(queryHelpers.down('laptop'));
  const pageHeaderBackgroundHeightPadding = isLaptopDown ? 60 : 112;

  useLayoutEffect(() => {
    const reportWindowSize = () => {
      setContentHeight(contentRef?.current?.clientHeight ?? contentHeight);
    };
    if (contentHeight !== undefined)
      window.addEventListener('resize', reportWindowSize);
    return () => window.removeEventListener('resize', reportWindowSize);
  }, [contentHeight]);

  useEffect(() => {
    if (contentRef?.current?.clientHeight)
      setContentHeight(contentRef.current.clientHeight);
  }, []);

  return (
    <Box
      css={{
        marginTop: '$two',
        marginBottom: '$two',
        '@laptop': {
          marginTop: '56px',
          marginBottom: '56px',
        },
        '@desktop': {
          marginTop: '56px',
          marginBottom: '56px',
        },
      }}
    >
      <PageHeaderBackground
        height={`${contentHeight + pageHeaderBackgroundHeightPadding / 2}px`}
        pageHeaderBackgroundHeight={`${
          contentHeight + pageHeaderBackgroundHeightPadding
        }px`}
      >
        <StackLayout>
          <StackItem
            horizontalAlignment="start"
            verticalAlignment="top"
            ref={contentRef}
          >
            <StackLayout
              orientation={{
                '@initial': 'vertical',
                '@desktop': 'horizontal',
                '@laptop': 'horizontal',
              }}
            >
              {showBackButton && (
                <BackButtonArrow
                  css={
                    isLaptopDown
                      ? { marginBottom: '$half' }
                      : { marginTop: '$half', marginRight: '$one' }
                  }
                />
              )}
              {headingText || headingId ? (
                <HeadingText
                  level="1"
                  size={{
                    '@initial': 'xl',
                    '@laptop': 'xxl',
                    '@desktop': 'xxl',
                  }}
                >
                  {/* Use headingText if available to display text directly, or if headingId is present, use formatMessage */}
                  {headingText ||
                    (headingId && formatMessage({ id: headingId }))}
                </HeadingText>
              ) : (
                <Box css={{ height: '$three' }} />
              )}
            </StackLayout>
          </StackItem>
        </StackLayout>
      </PageHeaderBackground>
    </Box>
  );
};
