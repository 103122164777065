import React, { useEffect } from 'react';
import { LoadingIndicator } from '@leagueplatform/web-common-components';
import { useHistory } from '@leagueplatform/routing';
import { APP_MANAGED_ROUTES } from 'common/app-managed-routes';
import { FeatureFlag } from '../../hooks/use-get-feature-flag.hook';

/**
 * Conditionally renders a path or route based on firebase feature flag value.
 * If feature flag is enabled, the passed in children props will be rendered.
 * If feature flag is disabled, the app will be redirected to a fallback url path.
 *
 * @param useGetAppFeatureFlag - App specific feature flag hook with logic to retrieve feature flag value.
 * @param flagName - The feature flag name to check in Firebase Remote Config.
 * @param children - The child React elements to render if specified feature flag is enabled.
 * @param fallBackRedirectPath - [Optional] The url path to redirect the app to if feature flag is disabled. Defaults to the home page.
 * @returns The passed in children prop or null if feature flag is disabled.
 */
export const FeatureFlagConditionalPage = ({
  useGetAppFeatureFlag,
  flagName,
  children,
  fallbackRedirectPath = APP_MANAGED_ROUTES.HOME,
}: {
  useGetAppFeatureFlag: (flagName: string) => FeatureFlag;
  flagName: string;
  children: JSX.Element;
  fallbackRedirectPath?: string;
}) => {
  const { isEnabled, isLoading } = useGetAppFeatureFlag(flagName);

  const history = useHistory();
  useEffect(() => {
    if (!isEnabled && !isLoading) {
      history.push(fallbackRedirectPath);
    }
  }, [isEnabled, isLoading, history, fallbackRedirectPath]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return isEnabled ? children : null;
};
