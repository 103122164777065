import {
  GDSStyleObject,
  Icon,
  queryHelpers,
  TextAction,
  useMediaQuery,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { useHistoryGoBack } from 'hooks/use-history-go-back.hook';

export const BackButtonArrow = ({
  fallbackPath,
  css,
}: {
  fallbackPath?: string;
  css?: GDSStyleObject;
}) => {
  const { goBack } = useHistoryGoBack(fallbackPath);
  const isMobile = useMediaQuery(queryHelpers.down('laptop'));
  const { formatMessage } = useIntl();
  return (
    <TextAction
      onClick={goBack}
      as="button"
      css={{
        color: '$onSurfaceIconPrimary',
        ...css,
      }}
    >
      <Icon
        icon={isMobile ? 'interfaceChevronLeft' : 'interfaceArrowLeft'}
        label={formatMessage({ id: 'GO_BACK' })}
      />
    </TextAction>
  );
};
