import { BACKGROUND_INFORMATION_LOCALE_STRINGS_EN } from '@highmark-web/highmark-common';

export const EN_STRING_OVERRIDES = {
  // Common locale strings imported from highmark-common
  ...BACKGROUND_INFORMATION_LOCALE_STRINGS_EN,
  // App-specific overrides
  ABOUT_IEL: 'About My Highmark',
  ABOUT_IEL_BODY_1:
    'This software does not perform any of the following: diagnosing, treating, mitigating or preventing a disease, disorder or abnormal physical state (or any of their symptoms), nor does it otherwise meet the definition of Software as a Medical Device (SaMD).',
  ACCOUNT_MANAGEMENT: 'Account Management',
  ACCOUNT_SETUP_FORM_HEADING: 'Complete Your Account',
  ACTIVITIES: 'Activities',
  ADD_PROGRAM: 'View program library',
  ADVANCE_TO_NEXT: 'Advance to next',
  ALL_LANGUAGES: 'All Languages',
  AMWELL_DISCLAIMER: 'Amwell Disclaimer',
  AMWELL_DISCLAIMER_HEADING: 'Legal Guardian’s Presence Required',
  AMWELL_DISCLAIMER_BODY_1:
    'Our records indicate that this account is registered to a member under age of 18. Members under the age of 18 are not permitted to use Well360 Virtual health without the presence of a legal guardian.',
  AMWELL_DISCLAIMER_BODY_2:
    'To access this service, please ask your legal guardian to schedule an appointment on your behalf. Your legal guardian will need to be present during your well360 virtual health appointment.',
  AND: 'and',
  APP_WALKTHROUGH_1_HEADING: 'Understand Your Benefits',
  APP_WALKTHROUGH_1_BODY:
    'Track insurance claims|Know what you owe for your care and how much your plan will cover.||Understand your coverage|View your medical, prescription, and vision benefits — all in one place.||Access additional support|Discover diabetes prevention programs, maternity resources, and more.',
  APP_WALKTHROUGH_2_HEADING: 'Get Access to Care',
  APP_WALKTHROUGH_2_BODY:
    'Find the right provider|Use our Provider Search tool to locate the care you need, when you need it.||Discover virtual care|Access virtual health visits, physical therapy, and mental well-being resources from the comfort of your own home.',
  APP_WALKTHROUGH_3_HEADING: 'Build New Healthy Habits',
  APP_WALKTHROUGH_3_BODY:
    'Explore wellness activities|Improve your health and well-being with step-by-step programs.||Join challenges|Meet your health goals with individual or group activities.',
  APP_WALKTHROUGH_4_HEADING: 'Find The Support You Need',
  APP_WALKTHROUGH_4_BODY:
    'Chat with our team|Get quick answers when you need them through our Chatbot and live chat.||Message customer support|Connect with a customer support agent to help answer more involved questions.||View your health plan documents|Access the forms you need, when you need them.',
  APP_WALKTHROUGH_5_HEADING: 'Receive Personalized Recommendations',
  APP_WALKTHROUGH_5_BODY:
    'Get guidance that works for you| Complete a questionnaire for personalized recommendations.||Take control of your health|Work towards your health goals with programs picked for your needs.',
  APPS_AND_DEVICES_CURRENTLY_SUPPORTED: ' ',
  AUTHED_WEBVIEW_MODAL_TITLE: 'Highmark Portal',
  BAGGAGE_ICON: 'Baggage icon',
  BILLING_ID: 'Billing ID',
  BLOCKER_ALT_TEXT: 'end of road with warning sign',
  BLOCKER_TITLE: 'Your plan period has ended',
  BLOCKER_DESCRIPTION:
    'Because your plan period has ended, you will have limited access to certain sections and features of this site.',
  CALL_MEMBER_SERVICES_HEADING: 'Call Member Services',
  CALL_MEMBER_SERVICES_BODY:
    'Talk to a member advocate to find answers to questions about any of your benefits or get help with questions about your care needs.',
  CANCEL: 'Cancel',
  CARE_COLLECTIONS: 'Health Advice and Support',
  CHALLENGES_LEGAL_DISCLAIMER: ' ',
  CHECK_TO_CONFIRM_PRIVACY_POLICY:
    'By checking this box, I agree to the above.',
  CLOSE: 'Close',
  CONNECT_DEVICES_LEARN_MORE: ' ',
  CONNECTING_UNAVAILABLE_DETAILS:
    'For now, please visit the mobile app to connect your favorite health apps and devices.',
  CONNECTING_UNAVAILABLE_DETAILS_WITH_LINK:
    'For now, please visit the mobile app to connect your favorite health apps and devices.',
  CONTINUE: 'Continue',
  CONTINUE_TO_APP: 'Continue to My Highmark',
  DEDUCTIBLE_DESCRIPTION_1:
    'A deductible is the amount of money you must pay out-of-pocket (with your own money) in a plan year before your insurance coverage starts. Once you meet the deductible, your insurance plan will offer coverage in the form of cost sharing. The deductible applies to all or most items and services covered by your plan.',
  DEDUCTIBLE_DESCRIPTION_2:
    "If you have a family deductible and the costs for you and your dependents combined meet this deductible, your plan will start to offer coverage for your family's future costs.",
  DISCLAIMER_BODY_1:
    '\u00AE Highmark is a registered mark of Highmark Inc. ©2021 Highmark Inc., All Rights Reserved.',
  DISCLAIMER_BODY_2:
    '\u00AE Blue Shield and the Shield symbol are registered service marks of the BlueCross and Blue Shield Association, an association of independent Blue Cross and Blue Shield plans.',
  DISCLAIMER_BODY_3:
    'Important Legal Information: Health care benefit programs are issued or administered by or through Highmark Blue Shield, Highmark Select Resources, Highmark Benefits Group, [Highmark Senior Health Company,] or Highmark Health Insurance Company, all of which are independent licensees of the Blue Cross and Blue Shield Association, an association of independent Blue Cross and Blue Shield plans. Information on this website is issued by Highmark Blue Shield on behalf of these companies, which serve the 21 counties of central Pennsylvania and 13 counties in northeast and north central Pennsylvania. Highmark Blue Shield also provides services in conjunction with Independence Blue Cross in southeastern Pennsylvania.',
  DISCLAIMER_CTA_APPSTORE: 'Download on the App Store',
  DISCLAIMER_CTA_PLAYSTORE: 'Download on the Play Store',
  DISCOVER_ACTIVITIES: 'Find more activities to do',
  DISCOVER_ACTIVITIES_CAPTION: 'A curated list of activities relevant to you',
  DOCUMENT_TITLE: '{title} - My Highmark',
  DONE: 'Done',
  DONT_HAVE_THE_APP: 'Don’t have the mobile app?',
  EBILL_BODY:
    'Manage one-time or automated monthly premium payments and view coverage invoices',
  EBILL_CTA: 'Go to eBill',
  EBILL_HEADER: 'Pay your premium',
  EBILL_SUBHEADER: 'eBill Payments and Invoices',
  E_BILLING_MODAL_LABEL: 'eBill registration',
  E_BILLING_REGISTRATION_TITLE: 'eBill registration',
  E_BILLING_REGISTRATION_DESCRIPTION_P1:
    'Our online system will enable you to set up either a one-time payment or recurring payments to pay for your health insurance coverage.',
  E_BILLING_REGISTRATION_DESCRIPTION_P2:
    'You are required to complete a one-time setup by providing your billing ID as shown on a copy of your paper bill. Don’t forget to go paperless.',
  EMAIL_OPT_IN:
    'I’d like to receive occasional emails with progress updates, earned badges, and tips to get the most out of My Highmark.',
  ERROR_CAPTION_BACKEND:
    'There was a problem connecting to our server.\nPlease try again later.',
  ERROR_CAPTION_NO_CONNECTION:
    'There was a problem loading data.\nCheck your internet and try again.',
  ERROR_CAPTION_NOT_ELIGIBLE:
    'Until then, use the website listed on the back of your member ID card.',
  ERROR_CAPTION_OVER_365_DAYS:
    'You no longer have access because our records indicate your health coverage ended over 365 days ago. If you believe there has been an error, please call 1-877-298-3918 and refer to code ML000-NOCVG. Normal operating hours are Monday – Friday, from 8 a.m. – 5 p.m., Eastern Time.',
  ERROR_CAPTION_PROFILE: 'Error updating user profile.',
  ERROR_CAPTION_SAVE: 'Error saving data. Please try again.',
  ERROR_CAPTION_TERMS:
    'Please agree to the Privacy Policy and Terms of Service.',
  ERROR_HEADING_GENERIC: 'Something went wrong',
  ERROR_DESCRIPTION_GENERIC:
    'Something went wrong on our end.\n Please try again later.',
  ERROR_HEADING_NO_CONNECTION: 'No internet connection',
  ERROR_HEADING_NOT_ELIGIBLE:
    "We are currently working to improve your online experience, and we'll let you know when it's ready.",
  ERROR_HEADING_NOT_ELIGIBLE_BOXOUT:
    'It looks like your account is linked to a different website.',
  ERROR_CAPTION_NOT_ELIGIBLE_BOXOUT_ALLMYHEALTH:
    'Click below to log into AllMyHealth.',
  ERROR_CAPTION_NOT_ELIGIBLE_BOXOUT_BENEFICITY:
    'Click below to log into Beneficity.',
  GO_TO_BENEFICITY: 'Go To Beneficity',
  GO_TO_ALLMYHEALTH: 'Go To AllMyHealth',
  ERROR_HEADING_OVER_365_DAYS: 'You no longer have access to this website',
  EXTERNAL_URL_REDIRECT_FAILED:
    'Something went wrong trying to redirect to external site, please try again later.',
  FOR_ASSISTANCE_WITH_LANGUAGES:
    'For assistance with languages other than English.',
  FOR_PROVIDERS_AND_PHARMACISTS: 'Policy Numbers',
  FORM_LIBRARY: 'Form Library',
  GENDER_IDENTITY_SELECT_PLACEHOLDER: 'Select your gender identity',
  GENDER_IDENTITY_TOOLTIP:
    'We support gender expression and we’d like to make sure we get yours right. Gender identity is the personal sense of your own gender. It may or may not be the same as your biological sex at birth.',
  GET_ASSISTANCE_NOW: 'Get help now',
  GET_STARTED: "Let's Get Started",
  GET_SUPPORT_SUBTITLE: 'Get support',
  GREETINGS_SUBHEADING:
    'Easily manage your health — and health coverage — with My Highmark.',
  HEALTH: 'Health Profile',
  HOW_CAN_WE_HELP: 'How can we help?',
  HSA_ACCOUNT_CREATED: 'HSA account created',
  HSA_BODY:
    'Your savings await! But first things first—you need to open your account.',
  HSA_CTA: 'Open your account',
  HSA_HEADER: 'Save for your healthcare',
  HSA_SUBHEADER: 'Open your HSA',
  HSA_MODAL_LABEL: 'HSA Registration',
  HSA_MODAL_HEADER: 'Congrats on choosing an HSA!',
  HSA_MODAL_BODY:
    'We need a few days to open your account. Look for your debit card and other communication in the mail. Also, keep an eye on your activities for more information on what to do next.',
  HSA_PENDING_BANNER_TITLE: 'Your HSA is almost ready',
  HSA_PENDING_BANNER_BODY:
    'It usually takes a few days to set up. Keep an eye on your activities for what to do next.',
  HSA_PAGE_CONTENT_1:
    'Since you’re enrolled in a qualified high-deductible health plan, you have access to a health savings account, or HSA. Opening your account is as simple as clicking the button below.',
  HSA_PAGE_ADVANTAGE_HEADER: 'Advantages of your HSA',
  HSA_PAGE_ADVANTAGE_1:
    'HSAs are a convenient way to save for qualified health care expenses, such as: medical, dental, and vision services, prescription drugs, and many over-the-counter health products.',
  HSA_PAGE_ADVANTAGE_2:
    'You can add, invest, and use funds without being taxed!',
  HSA_PAGE_ADVANTAGE_3:
    'The account is yours to keep and grow, wherever you go. It’s quick to open, easy to use, and you can set up your account with communication preferences, direct deposit, and more.',
  HSA_PAGE_CTA_SECONDARY: 'Do this later',
  LEARN_MORE_ABOUT_PRIVACY_POLICY: ' ',
  LEAVE_SITE_DISCLAIMER: 'Leaving Site Disclaimer',
  LEAVE_SITE_DISCLAIMER_HEADING_YMCA:
    'You will be redirected to the YMCA site.',
  LEAVE_SITE_DISCLAIMER_HEADING_LIVONGO:
    'You will be redirected to the Livongo site.',
  LEAVE_SITE_DISCLAIMER_BODY:
    'You will be taken to a third-party website, owned, and operated by an independent party over which your Health Plan has no control.  Any use of the third-party website and any information you provide will be subject to and governed by the terms of the third-party website, including those relating to confidentiality, data privacy and security.',
  LEGAL: 'Legal',
  LEGAL_MODAL_TITLE: 'Legal Disclaimer',
  LEGAL_MODAL_BODY_1:
    'We are committed to providing outstanding services to our applicants and members. If you require special assistance, including accommodations for disabilities or limited English proficiency, please call us at 1-800-876-7639 or TTY at #711 to receive assistance free of charge.',
  LEGAL_MODAL_BODY_2:
    'The information contained on this website regarding the Patient Protection and Affordable Care Act of 2010 (“PPACA”), as amended, and/or any other law, does not constitute legal or tax advice and is subject to change based upon the issuance of new guidance and/or change in laws.',
  LEGAL_MODAL_BODY_3:
    'The information provided is intended to provide general information only and does not attempt to give you advice that relates to your specific circumstances. State laws and regulations governing health insurance and health plans may vary from state to state. Further, any information regarding any health plan will be subject to the terms of its particular health plan benefit agreement and some health plans may not be available in every region or state.',
  LEGAL_MODAL_BODY_4:
    'This site works best if viewed with the latest version of Edge, Firefox, Chrome, or Safari browsers. For the best performance and security, always keep your web browser up-to-date.',
  LIVE_CHAT: 'Chat',
  LIVE_CHAT_GENERIC_ERROR_TITLE: 'Unable to connect to chat',
  LIVE_CHAT_GENERIC_ERROR_DESCRIPTION:
    'There was an issue connecting to the chat service. Please visit the support page for additional ways to contact our team.',
  LIVE_CHAT_UNREAD_MESSAGES: 'Chat, you have {count} unread messages.',
  MAIN_NAVIGATION_LIST_LABEL: 'Main navigation links',
  MEDICAL_INSTRUMENT_ICON: 'Medical instrument icon',
  MESSAGE_THREADS_LIST_SUBTITLE: ' ',
  MFA_MODAL_TITLE: 'Multi-factor Authentication Enrollment',
  MFA_DESCRIPTION:
    'Make your account more secure by adding an additional layer of protection. Just make sure all of your contact information is up-to-date.',
  MFA_TITLE:
    'Multi-factor authentication is required to keep your account safe.',
  NEXT: 'Or, press enter',
  NO_INTERNET: 'Internet connection error',
  NON_DISCRIMINATION_POLICY: 'Non-Discrimination Policy',
  OKAY: 'Okay',
  OUT_OF_POCKET_MAXIMUM_DESCRIPTION:
    'An out-of-pocket (OOP) maximum is the most money you can pay during your plan year. Once you meet the maximum, your plan will cover a percentage of future costs for the rest of the plan year.',
  PRIVACY_POLICY: 'Privacy Policy',
  MULTIPLE_ACTIVE_PLANS: 'You have multiple active plans',
  MULTIPLE_ACTIVE_PLANS_INFO:
    'You are currently viewing information for your {plan} plan. Would you like to view a different plan?',
  PERSONALIZED_WELLNESS_PLAN: 'Personalized Wellness Plan',
  PROFILE: 'Health Profile',
  PRONOUNS_SELECT_PLACEHOLDER: 'Select your pronouns',
  PRONOUNS_TOOLTIP:
    'Add your pronouns so we know how to refer to you. For example: If Alex’s pronouns are they/them, we could say “Alex recently enrolled in a mental health program, and they’ve noticed a boost in their mental wellbeing.”',
  PROVIDED_BY_WITH_COLON: 'Provided by:',
  READ_MORE: 'Read more',
  RELOAD: 'Reload',
  RETURN_TO_PREVIOUS: 'Return to previous',
  SAVING_PIGGY_ICON: 'Saving piggy icon',
  SELECT_PLAN: 'Select plan to view',
  SET_UP_NOW: 'Set up now',
  SKIP_WALKTHROUGH_INTRODUCTION: 'Skip introduction',
  START_A_VISIT: 'Start Virtual Care Visit',
  START_COBROWSE: 'Generate Code to CoBrowse',
  START_COBROWSE_DESCRIPTION:
    'With CoBrowsing, our customer service team can access your device and see your screen to better help you.',
  SWITCH_VIEW: 'Switch view',
  SUGGESTED_FOR_YOU: 'Suggested for You',
  SUPPORT: 'Support',
  SUPPORT_PAGE: 'Support Page',
  SUPPORTS_GENDER_EXPRESSION_SUBHEADING:
    'myHighmark supports gender expression and would like to know how you identify',
  SYMPTOM_CHECKER_DISCLAIMER: 'Symptom Checker Disclaimer',
  TRACK_CHALLENGE_AUTOMATICALLY_DESCRIPTION:
    'If you choose to track automatically, connect your device under the Apps & devices section of the MyHighmark mobile app, after you’ve signed up for this challenge.',
  TERMS_AGREEMENT: 'I have read and agreed to the ',
  TERMS_SERVICE: 'Terms of Service',
  TRY_OUR_APP: 'This experience looks better in the app!',
  THIS_INFO_WILL_NOT_BE_SHARED:
    'This information will not be shared with your employer',
  UNLOCK_ACHIEVEMENTS:
    'Earn an achievement for completing all activities in this program.',
  VIEW_ALL: 'View all',
  VIEW_PLAN: 'View plan',
  WALLET: 'Benefits',
  WALLET_LANDING_TITLE: 'Your benefits and wellness info, all in one place.',
  // This is not trying to template a string, we want a dollar sign before the points.
  // eslint-disable-next-line no-template-curly-in-string
  X_LEAGUE_POINTS: '${points} Wellness Rewards',
  YOUR_JOURNEY: "Today's Activities",
  CLAIMS_SUMMARY_PAGE_SUB_HEADING:
    "View what your insurance has covered, and what you may still owe your provider. You can access your Highmark claims submitted within two years to today's date.",
  WALLET_WITH_CLAIMS_LANDING_TITLE:
    'Your benefits, claims, and wellness info, all in one place.',
  WELLNESS_REWARDS: 'Wellness Rewards',
  MESSAGE_CLOSED_TEXT:
    'This message has been delivered. To start a new message, please visit the Support Page.',
  QUESTIONNAIRE_FLOW_CONTINUE: 'Agree',
  ABOUT_ME: 'Health Dashboard',
  CLAIMS_PROCESSING_DESCRIPTION:
    "You'll receive a bill associated with this claim from your provider.",
  CLAIMS_SERVICE_DETAILS_DATE: 'Date(s) of Service',
  TECHNICAL_ASSISTANCE_AND_ALL_LANGUAGES_TITLE:
    'Technical Assistance and All Languages',
  TECHNICAL_SUPPORT: 'Technical Support',
  TECHNICAL_SUPPORT_DESCRIPTION:
    'Have questions about benefits or claims? Or need support in your language? Please call the number on the back of your member ID card. We provide free accommodations for those with disabilities. TTY users call 1-800-452-8086 or dial 711. If you have a technical question about this website, please call 1-877-298-3918.',
  TOOLBOX_VALIDATION_MULTI_SELECT_REQUIRED:
    'Please select an answer to continue',
  GIVE_FEEDBACK: 'Give Feedback',
  ENABLE_GENESIS_CORE_INSPECTOR: 'Enable Genesis Core Inspector',
  DISABLE_GENESIS_CORE_INSPECTOR: 'Disable Genesis Core Inspector',
};
