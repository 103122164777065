import { LeagueConfig, setConfig } from '@leagueplatform/core';
import { useEffect } from 'react';
import { useFirebaseLeagueConfig } from './use-firebase-league-config.hook';
import { getClaimsFirebaseConfig } from './get-claims-firebase-config';
import { getLiveChatFirebaseConfig } from './get-live-chat-firebase-config';
import { getWalletFirebaseConfig } from './get-wallet-firebase-config';

export const useDynamicConfig = (isCareCollectionsChip: boolean = false) => {
  const {
    claimsDynamicFilters,
    claimsDetails,
    claimsDownload,
    claimsFilterChips,
    liveChatNavigateAway,
    walletLiveBalances,
  } = useFirebaseLeagueConfig();

  useEffect(() => {
    setConfig((config) => {
      const careCollectionsConfig = isCareCollectionsChip
        ? { queryTagForCollectionsTopics: 'chip' }
        : {};

      // eslint-disable-next-line no-param-reassign
      config.careCollections = {
        ...config.careCollections,
        ...careCollectionsConfig,
      };

      // eslint-disable-next-line no-param-reassign
      config.claims = {
        ...config.claims,
        ...getClaimsFirebaseConfig({
          claimsDynamicFilters,
          claimsDetails,
          claimsDownload,
          claimsFilterChips,
        }),
      };

      // eslint-disable-next-line no-param-reassign
      config.liveChat = {
        ...config.liveChat,
        ...getLiveChatFirebaseConfig({ liveChatNavigateAway }),
      } as LeagueConfig['liveChat'];

      // eslint-disable-next-line no-param-reassign
      config.wallet = {
        ...config.wallet,
        ...getWalletFirebaseConfig({ walletLiveBalances }),
      };

      return config;
    });
  }, [
    isCareCollectionsChip,
    claimsDynamicFilters,
    claimsDetails,
    claimsDownload,
    claimsFilterChips,
    liveChatNavigateAway,
    walletLiveBalances,
  ]);
};
