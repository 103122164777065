import { MasonryEngine } from '@leagueplatform/masonry-engine';
import { getMasonryWSDriver } from '@leagueplatform/masonry';
import { PRODUCT_AREA, usePageViewAnalytics } from '@leagueplatform/analytics';
import {
  ANALYTICS_SCREEN_NAMES,
  DESKTOP_SINGLE_COLUMN_CONTENT_WIDTH,
  MASONRY,
} from 'common/constants';
import {
  Box,
  StackLayout,
  queryHelpers,
  useMediaQuery,
} from '@leagueplatform/genesis-core';
import { LEAGUE_MODULE_NAMES, getModulePath } from '@leagueplatform/core';
import { BackButtonArrow } from 'components/back-button-arrow/back-button-arrow.component';
import { MasonryPageContainer } from 'pages/masonry/masonry-container.page';
import { useRouteMatch } from '@leagueplatform/routing';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { useIntl } from '@leagueplatform/locales';

// A wrapper component is required to initialize the MasonryWSDriver with a dynamic ID
const PWPMasonryEngineWrapper = ({ appId }: { appId: string }) => {
  const driver = getMasonryWSDriver({
    appId,
    screenName: ANALYTICS_SCREEN_NAMES.PWP,
    productArea: PRODUCT_AREA.JOURNEY,
  });
  return <MasonryEngine driver={driver} />;
};

/**
 * Medicare Advantage Personalized Wellness Plan Main Page
 */
export const PWPMainPage = () => {
  const { formatMessage } = useIntl();
  useDocumentTitle(formatMessage({ id: 'PERSONALIZED_WELLNESS_PLAN' }));
  usePageViewAnalytics({ screen_name: ANALYTICS_SCREEN_NAMES.PWP });
  const baseRoute = getModulePath(LEAGUE_MODULE_NAMES.healthJourney);

  // The ID here is expected to be the PWP year. A corresponding masonry container would need to be available.
  const { params } = useRouteMatch<{ id: string }>();
  const { id } = params;

  const isMobile = useMediaQuery(queryHelpers.down('tablet'));
  let masonryContainerId = `${MASONRY.APP_ID_PWP_MAIN_PAGE}_${id}`;

  if (!id) {
    masonryContainerId = MASONRY.APP_ID_PWP_MAIN_PAGE;
  }

  return (
    <MasonryPageContainer>
      <StackLayout
        orientation={isMobile ? 'vertical' : 'horizontal'}
        spacing="$one"
        css={{
          marginTop: '$three',
          '@mobile': { marginTop: '$two' },
          '@mobileLandscape': { marginTop: '$two' },
        }}
      >
        <Box css={{ marginBlock: '$half' }}>
          <BackButtonArrow fallbackPath={baseRoute} />
        </Box>

        <Box
          css={{ maxWidth: DESKTOP_SINGLE_COLUMN_CONTENT_WIDTH, width: '100%' }}
        >
          <PWPMasonryEngineWrapper appId={masonryContainerId} />
        </Box>
      </StackLayout>
    </MasonryPageContainer>
  );
};
