import { getPartnerAccessToken } from '@leagueplatform/sso-util';
import { DEFAULT_PARTNER_ID } from 'common/constants';
import { logger } from './logger';
import { getTrustedTargetSsoOrigins } from './sso-url-helpers';

/**
 * Additional values that the partner has declared allowing us to forward SSO redirect context.
 */
export type PartnerDefaultOverrideValues = {
  clientNumber?: string;
};

/**
 * This method is used for sending an auth token using the postMessage method to a cross-origin window object.
 * The main use case is for authenticating a user session in an embedded iframe or new window using an access token provided by an IDP.
 *
 * @param partnerId {string} - The ID of the partner IDP to retrieve the necessary access token from
 * @param targetWindow {string} - The target window object to send the auth token
 * @param targetOrigin {string} - The target origin of the Iframe to send the message to
 */
export const sendPartnerAuthToken = async (
  partnerId: string,
  targetWindow: Window,
  targetOrigin: string,
  defaultOverrideValues?: PartnerDefaultOverrideValues,
) => {
  if (!getTrustedTargetSsoOrigins().includes(targetOrigin)) {
    throw new Error(
      `Token is not allowed to be sent to target origin: ${targetOrigin}`,
    );
  }
  const token = await getPartnerAccessToken(partnerId);

  targetWindow.postMessage(
    {
      accessToken: token,
      defaultOverrideValues,
    },
    targetOrigin,
  );
};

/**
 * This function handles the ready message event sent from a child iframe or window.
 * If ready message received, then send the partner access token that is required
 * to authenticate the session in the child window.
 *
 * @param event {MessageEvent} - Event to be handled
 * @param origin {string} - origin of where the Event is sent from
 * @param window {Window} - window object of the sent event
 * @param partnerId {string} - Optional: partnerId used to fetch auth token
 *
 * @returns boolean - If token was successfully sent, returns true. Otherwise returns false
 */

export const sendAuthTokenIfReady = async (
  event: MessageEvent,
  origin: string,
  window: Window | null | undefined,
  partnerId?: string,
  defaultOverrideValues?: PartnerDefaultOverrideValues,
) => {
  if (event.origin === origin && event.data?.ready === true) {
    if (!window) {
      throw Error('Could not send auth token, target window does not exist.');
    }
    await sendPartnerAuthToken(
      partnerId || DEFAULT_PARTNER_ID,
      window,
      origin,
      defaultOverrideValues,
    );
    logger.log('Sent auth token to: ', origin);
    return true;
  }
  return false;
};
